import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const editExtension = createAsyncThunk(
  "extension/editExtension",
  async (extension, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios({
        method: "post",
        url: `/extensions/update/${extension.id}`,
        data: extension,
      });
      if (data.status === 200) {
        dispatch(setMessage("تم تعديل التحويلة بنجاح"));
      }
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default editExtension;
