import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import AddEmployee from "../../../quailty-ui/employees/AddEmployee";
import withGuard from "../../../utils/withGurard";

const AddEmployess = () => {
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="الموظفين"
        dashboard="لوحة التحكم"
        breadcrumb="أضف موظف"
      />
      <AddEmployee />
    </>
  );
};

export default withGuard(AddEmployess);
