import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkUser } from "../../../store/auth/logIn";
import { useEffect } from "react";
import { useCallback } from "react";
import jwt_decode from "jwt-decode";

const Login = () => {
  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state.logIn);
  const navigate = useNavigate();

  const handleCallbackResponse = useCallback(
    (response) => {
      localStorage.setItem("access_token", response.credential);
      var userObject = jwt_decode(response.credential);
      dispatch(checkUser(userObject.email));
    },
    [dispatch]
  );

  useEffect(() => {
    if (user) {
      localStorage.setItem("role", user.role);
      localStorage.setItem("id", user.id);
      localStorage.setItem("email", user.email);
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    /*global google*/
    google.accounts.id.initialize({
      client_id:
        "819871915377-c8hdi113p55vq6vp4d0s87k54mj7pg3c.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(document.getElementById("siginInDiv"), {
      theme: "outline",
      size: "large",
    });
  }, [handleCallbackResponse]);

  return (
    <div
      className="page-wrapper"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div className="card-body">
                  <span className="text-nowrap logo-img text-center d-block py-3 w-100">
                    <img
                      src="../assets/images/logos/naqiLogo.png"
                      width="120"
                      alt=""
                    />
                  </span>
                  <p className="text-center">Quality Evaluation system</p>
                  {error && (
                    <div className="alert alert-danger">
                      هذا البريد الالكتروني غير مسجل بالنظام
                    </div>
                  )}
                  <div
                    id="siginInDiv"
                    className="d-grid gap-2d-flex justify-content-center w-100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
