import { createSlice } from "@reduxjs/toolkit";
import fetchAllExtensions from "./actGetAllExtension";
import addExtension from "./actAddExtension";
import fetchSingleExtension from "./actGetSingelExtension";
import editExtension from "./actEdittExtension";
import deleteExtension from "./actactDeleteExtension";

const initialState = {
  data: [],
  error: null,
  loading: false,
  extention: null,
};

const extensionSlice = createSlice({
  name: "extension",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    // ========================== get data from server ==========================
    builder.addCase(fetchAllExtensions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllExtensions.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAllExtensions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== add data from server ==========================
    builder.addCase(addExtension.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addExtension.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(addExtension.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== read data from server ==========================
    builder.addCase(fetchSingleExtension.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSingleExtension.fulfilled, (state, action) => {
      state.loading = false;
      state.extention = action.payload;
    });
    builder.addCase(fetchSingleExtension.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== edit data from server ==========================
    builder.addCase(editExtension.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(editExtension.fulfilled, (state, action) => {
      state.loading = false;
      state.extention = action.payload;
    });
    builder.addCase(editExtension.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== delete data from server ==========================
    builder.addCase(deleteExtension.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteExtension.fulfilled, (state, action) => {
      state.loading = false;
      state.data = state.data.filter((el) => el.id !== action.payload.id);
    });
    builder.addCase(deleteExtension.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export { fetchAllExtensions, fetchSingleExtension, editExtension };
export const { resetData } = extensionSlice.actions;
export default extensionSlice.reducer;
