import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import EditExtension from "../../../quailty-ui/all_extensions/EditExtension";
import withGuard from "../../../utils/withGurard";

const EditExtensions = () => {
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="التحويلات"
        dashboard="لوحة التحكم"
        breadcrumb="تعديلات علي التحويلة"
      />
      <EditExtension />
    </>
  );
};

export default withGuard(EditExtensions);
