import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import violation from "../../../store/call/actViolation";
import { resetData, success } from "../../../store/call/callSlice";

const SalesEvaluation = () => {
  const [isViolation, setIsViolation] = useState(true);
  const { callDetails, call, loading } = useSelector(
    (state) => state.callSlice
  );
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      startCall: "",
      callRecord: "",
      customerData: "",
      customerRequest: "",
      productPrice: "",
      location: "",
      naqiProducts: "",
      reviewCustomarDataAndTime: "",
      confirmMessage: "",
      anotherServiceQuestion: "",
      socialMedia: "",
      concliding: "",
      comments: "",
    },
    onSubmit: (values) => {
      if (isViolation) {
        dispatch(
          violation({
            intro: values.startCall ? values.startCall : false,
            record_mention: values.callRecord ? values.callRecord : false,
            confrim_data: values.customerData ? values.customerData : false,
            confrim_order_data: values.customerRequest
              ? values.customerRequest
              : false,
            products_price: values.productPrice ? values.productPrice : false,
            installation_site_check: values.location ? values.location : false,
            other_products_mention: values.naqiProducts
              ? values.naqiProducts
              : false,
            date_data_check: values.reviewCustomarDataAndTime
              ? values.reviewCustomarDataAndTime
              : false,
            mention_msg: values.confirmMessage ? values.confirmMessage : false,
            other_services_check: values.anotherServiceQuestion
              ? values.anotherServiceQuestion
              : false,
            social_media_rate: values.socialMedia ? values.socialMedia : false,
            outro: values.concliding ? values.concliding : false,
            evaluator_note: values.comments ? values.comments : "",
            evaluator_id: localStorage.getItem("id"),
            uid: call ? call.call_id : "",
            call_from: call ? call.call_from : "",
            call_to: call ? call.call_to : "",
            call_date: call ? call.call_date : "",
            duration: call ? call.call_duration : "",
            status: call ? call.call_status : "",
            extension_id: callDetails ? callDetails.id.toString() : "",
            department_id: callDetails ? callDetails.department_id : "",
            form_type: callDetails ? callDetails.department.form_type : "",
            is_violation_evaluator: true,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetData());
            dispatch(success("تم تقييم المكالمة بنجاح"));
          })
          .catch((error) => {
            error && dispatch(resetData());
          });
      } else {
        dispatch(
          violation({
            intro: false,
            record_mention: false,
            confrim_data: false,
            confrim_order_data: false,
            products_price: false,
            installation_site_check: false,
            other_products_mention: false,
            date_data_check: false,
            mention_msg: false,
            other_services_check: false,
            social_media_rate: false,
            outro: false,
            evaluator_note: values.comments ? values.comments : "",
            evaluator_id: localStorage.getItem("id"),
            uid: call ? call.call_id : "",
            call_from: call ? call.call_from : "",
            call_to: call ? call.call_to : "",
            call_date: call ? call.call_date : "",
            duration: call ? call.call_duration : "",
            status: call ? call.call_status : "",
            extension_id: callDetails ? callDetails.id.toString() : "",
            department_id: callDetails ? callDetails.department_id : "",
            form_type: callDetails ? callDetails.department.form_type : "",
            is_violation_evaluator: false,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetData());
            dispatch(success("تم تقييم المكالمة بنجاح"));
          })
          .catch((error) => {
            error && dispatch(resetData());
          });
      }
    },
  });

  const disabledHandler = (obj) => {
    for (let key in obj) {
      if (
        (obj[key] === true && obj["comments"] === "") ||
        (obj[key] === true && obj["comments"])
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="startCall"
                onChange={formik.handleChange}
                value={formik.values.startCall}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم افتتاحية المكالمة (اسم الشركة والموظف)
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="callRecord"
                onChange={formik.handleChange}
                value={formik.values.callRecord}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم ذكر تسجيل المكالمة لغرض الجودة
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="customerData"
                onChange={formik.handleChange}
                value={formik.values.customerData}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم التأكد من اسم العميل والمدينة والحي
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="customerRequest"
                onChange={formik.handleChange}
                value={formik.values.customerRequest}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم التأكد من طلب العميل
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="productPrice"
                onChange={formik.handleChange}
                value={formik.values.productPrice}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم التأكد من أسعار المنتجات للعميل
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="location"
                onChange={formik.handleChange}
                value={formik.values.location}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم التأكد من جاهزية موقع التركيب
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="naqiProducts"
                onChange={formik.handleChange}
                value={formik.values.naqiProducts}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم اقتراح منتجات نقي
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="reviewCustomarDataAndTime"
                onChange={formik.handleChange}
                value={formik.values.reviewCustomarDataAndTime}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم مراجعة الموعد والبيانات مع العميل
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="confirmMessage"
                onChange={formik.handleChange}
                value={formik.values.confirmMessage}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم توضيح وصول رسالة تأكيد الموعد خلال 48 ساعة قبل الموعد عن
                طريق الواتس اب
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="anotherServiceQuestion"
                onChange={formik.handleChange}
                value={formik.values.anotherServiceQuestion}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم سؤال العميل عن اي خدمة اخري
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="socialMedia"
                onChange={formik.handleChange}
                value={formik.values.socialMedia}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم تقييم الخدمة عبر السوشيال ميديا
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="concliding"
                onChange={formik.handleChange}
                value={formik.values.concliding}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم قول الختامية
              </label>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 mt-2">
              <label
                className="mb-3 fw-bold"
                htmlFor="exampleFormControlTextarea1"
              >
                ملاحظات
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                name="comments"
                onChange={formik.handleChange}
                value={formik.values.comments}
              ></textarea>
            </div>
          </div>
          <div className="col-md-6">
            <button
              type="submit"
              onClick={() => setIsViolation(true)}
              className="btn btn-danger w-100"
              disabled={loading}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                <>مخالفة</>
              )}
            </button>
          </div>
          <div className="col-md-6">
            <button
              type="submit"
              onClick={() => setIsViolation(false)}
              className="btn btn-success w-100"
              disabled={loading || disabledHandler(formik.values)}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                <>لا توجد مخالفة</>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SalesEvaluation;
