import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import addDepartment from "../../../store/department/actAddDepartment";
import Error from "../error/Error";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

//import { useState } from "react";
const formSchema = Yup.object().shape({
  department: Yup.string().required("هذا الحقل مطلوب!"),
  scenario: Yup.string().required("هذا الحقل مطلوب!"),
});
const AddDepartment = () => {
  const { loading, error } = useSelector((state) => state.departmentSlice);
  //const { errors, setErrors } = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      department: "",
      scenario: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      dispatch(
        addDepartment({
          department: values.department,
          scenario: values.scenario,
        })
      )
        .unwrap()
        .then(() => navigate("/dashboard/all-departments"))
        .catch((error) => {});
    },
  });

  return (
    <>
      {error && <Error error={error} />}
      <div className="card">
        <form onSubmit={formik.handleSubmit}>
          <div className="card-body p-4">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">أضف قسم جديد </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control  ps-2"
                    placeholder="اسم القسم"
                    name="department"
                    onChange={formik.handleChange}
                    value={formik.values.department}
                    isInvalid={!!formik.errors.department}
                    isValid={
                      formik.touched.department && !formik.errors.department
                    }
                    onBlur={formik.handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.department}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>السيناريو</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="scenario"
                    onChange={formik.handleChange}
                    value={formik.values.scenario}
                    isInvalid={!!formik.errors.scenario}
                    isValid={formik.touched.scenario && !formik.errors.scenario}
                    onBlur={formik.handleBlur}
                  >
                    <option>اختر السيناريو الخاص بالقسم</option>
                    <option value="QualityEvaluation">سيناريو الجودة</option>
                    <option value="CoordinationEvaluation">
                      سيناريو التنسيق
                    </option>
                    <option value="SalesEvaluation">سيناريو المبيعات</option>
                    <option value="MultiEvaluation">
                      سيناريو قسم (الصيانة, الشكاوي, الخدمات, الرقم الموحد)
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.scenario}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={loading}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                <>أضف</>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddDepartment;
