import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import AddDepartment from "../../../quailty-ui/department/AddDepartment";
import withGuard from "../../../utils/withGurard";

const AddDepartments = () => {
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="جميع الأقسام"
        dashboard="لوحة التحكم"
        breadcrumb="أضف قسم"
      />
      <AddDepartment />
    </>
  );
};

export default withGuard(AddDepartments);
