import React from "react";
import { useNavigate } from "react-router-dom";
import withGuard from "../../utils/withGurard";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="position-relative overflow-hidden min-vh-100 d-flex align-items-center justify-content-center">
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="row justify-content-center w-100">
          <div className="col-lg-4">
            <div className="text-center">
              <img
                src="../assets/images/backgrounds/errorimg.svg"
                alt=""
                className="img-fluid"
              />
              <h1 className="fw-semibold mb-7 fs-9">Opps!!!</h1>
              <h4 className="fw-semibold mb-7">
                This page you are looking for could not be found.
              </h4>
              <button
                onClick={() => navigate("/dashboard", { replace: true })}
                className="btn btn-primary"
              >
                عد الي لوحة التحكم
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withGuard(ErrorPage);
