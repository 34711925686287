import { useCallback } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import deleteEmployee from "../../../store/employees/actDeleteEmployee";

const ModalEmployees = ({ employee }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const deleteEmployeeHandler = useCallback(
    (id) => {
      dispatch(deleteEmployee(id));
      handleClose();
    },
    [dispatch]
  );
  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="ti ri-delete-bin-6-line"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل أنت متأكد من حذف {employee.email}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            الغاء
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteEmployeeHandler(employee.id)}
          >
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalEmployees;
