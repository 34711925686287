import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const editEmployee = createAsyncThunk(
  "employee/editEmployee",
  async (employee, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `/users/update/${employee.id}`,
        headers: { Accept: "application/json" },
        data: employee,
      });
      if (data.status === 200) {
        dispatch(setMessage("تم تعديل بيانات المستخدم"));
      }
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default editEmployee;
