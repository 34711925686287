import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = { user: null, error: null };

export const checkUser = createAsyncThunk(
  "logIn/checkUser",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/users/user/${email}`);
      if (response.data.status === 404) {
        return rejectWithValue("هذا البريد الالكتروني غير مسجل بالنظام");
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const logIn = createSlice({
  name: "logIn",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkUser.fulfilled, (state, action) => {
      state.user = action.payload.data.data;
    });
    builder.addCase(checkUser.rejected, (state, action) => {
      state.error = action.payload.data.data;
    });
  },
});

export const { resetUser } = logIn.actions;
export default logIn.reducer;
