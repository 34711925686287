import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async (employee, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `/users/store`,
        data: employee,
      });

      if (data?.status === 200) {
        dispatch(setMessage("تم اضافة المستخدم بنجاح"));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors.email[0]);
    }
  }
);

export default addEmployee;
