import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchSingleDepartment = createAsyncThunk(
  "department/fetchSingleDepartment",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get(`/departments/department/${id}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default fetchSingleDepartment;
