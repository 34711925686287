import React from "react";

const EvaluatorvalidCallDetails = ({ violation }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row alig n-items-start">
          <div className="col-8">
            <h5 className="card-title p-2 fw-semibold d-flex align-items-center">
              تفاصيل تقييم المكالمة
            </h5>
          </div>
          <div className="col-4">
            <div className="d-flex justify-content-end">
              <div className="text-white bg-success rounded  p-2 d-flex align-items-center justify-content-center">
                لا توجد مخالفة
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12 mt-2">
            <h6 className="fw-semibold mb-3"> الملاحظات :</h6>
          </div>
          <div className="col-md-10 offset-1">
            <p className="fs-3 mb-3">
              {violation?.evaluator_note
                ? violation?.evaluator_note
                : "لا توجد ملاحظات"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluatorvalidCallDetails;
