import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import CallDetails from "../../../quailty-ui/callDetails/CallDetails";
import EvaluatorvalidCallDetails from "../../../quailty-ui/callDetails/EvaluatorvalidCallDetails";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import getEvaluatorSingleCallDetails from "../../../../store/evaluator/actGetSingleCallDetails";
import { resetCallDetails } from "../../../../store/evaluator/evaluatorSlice";
import Loading from "../../../quailty-ui/Loading_page/Loading";

const EvaluatorValidCallsDetailsPage = () => {
  const { callDetails, loading } = useSelector((state) => state.evaluatorSlice);

  const dispatch = useDispatch();
  const { id } = useParams();

  //get the call details
  useEffect(() => {
    dispatch(getEvaluatorSingleCallDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetCallDetails());
    };
  }, [dispatch]);
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="صفحة تفاصيل المكالمة"
        dashboard="لوحة التحكم"
        breadcrumb="تفاصيل تقييم المكالمة"
      />
      <Loading loading={loading}>
        <CallDetails CallDetails={callDetails} />
        <div className="row">
          <div className="col-md-6 offset-3">
            <EvaluatorvalidCallDetails violation={callDetails} />
          </div>
        </div>
      </Loading>
    </>
  );
};

export default EvaluatorValidCallsDetailsPage;
