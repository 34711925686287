import { createSlice } from "@reduxjs/toolkit";
import getauditorAllViolationCalls from "./actGetAllViolationCalls";
import fetchAuditorSingleViolation from "./actGetSingleviolation";
import sendAuditorEvaluation from "./actSendAuditorEvaluation";

const initialState = {
  data: [],
  error: null,
  loading: false,
  singleViolation: null,
};

const auditorSlice = createSlice({
  name: "auditor",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
      state.singleViolation = null;
    },
  },
  extraReducers: (builder) => {
    // ========================== get data from server ==========================
    builder.addCase(getauditorAllViolationCalls.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getauditorAllViolationCalls.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getauditorAllViolationCalls.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== read data from server ==========================
    builder.addCase(fetchAuditorSingleViolation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAuditorSingleViolation.fulfilled, (state, action) => {
      state.loading = false;
      state.singleViolation = action.payload;
    });
    builder.addCase(fetchAuditorSingleViolation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== send Auditor Evaluation data to server ==========================
    builder.addCase(sendAuditorEvaluation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendAuditorEvaluation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetData } = auditorSlice.actions;
export default auditorSlice.reducer;
