import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios.get(`/users/remove/${id}`);
      if (data.status === 200) {
        dispatch(setMessage("تم حذف المستخدم"));
      }
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default deleteEmployee;
