import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import useEvaluatorValidCallsDataTable from "../../../hooks/evaluator/use-evaluator-valid-calls-dataTable";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import Loader from "../../../quailty-ui/loader/Loader";

const EvaluatorValidCallsPage = () => {
  const {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    loading,
  } = useEvaluatorValidCallsDataTable();
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="المكالمات المقيمة"
        dashboard="لوحة التحكم"
        breadcrumb="المكالمات الصحيحة"
      />
      <div className="card">
        <div className="card-body">
          <DataTableExtensions {...tableData} filterPlaceholder="بحث">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              highlightOnHover
              pointerOnHover
              customStyles={customStyles}
              defaultSortFieldId={1}
              persistTableHead
              noDataComponent={
                <span className="p-5">لا توجد بيانات لعرضها</span>
              }
              noContextMenu
              selectableRows
              selectableRowsHighlight
              progressPending={loading}
              progressComponent={<Loader />}
            />
          </DataTableExtensions>
        </div>
      </div>
    </>
  );
};

export default EvaluatorValidCallsPage;
