import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const GuardedRoute = ({ children, userRoles }) => {
  const navigate = useNavigate();
  const requiredRoles = [
    "System Admin",
    "Evaluator",
    "Employee Supervisor",
    "Quality Auditor",
    "Quality Dept. Manager",
  ];

  const searchRole = requiredRoles.filter((role) => userRoles.includes(role));

  useEffect(() => {
    if (!searchRole.includes(localStorage.getItem("role"))) {
      navigate("/dashboard");
    }
  }, [navigate, searchRole]);

  return children;
};

export default GuardedRoute;
