import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const sendSupervisorEvaluation = createAsyncThunk(
  "supervisor/sendSupervisorEvaluation",
  async (SupervisorEvaluation, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `/evaluations/store/supervisor/${SupervisorEvaluation.id}`,
        data: {
          supervisor_id: SupervisorEvaluation.supervisor_id,
          is_violation_supervisor: SupervisorEvaluation.is_violation_supervisor,
          supervisor_note: SupervisorEvaluation.supervisor_note,
        },
      });
      if (data.status === 200) {
        dispatch(setMessage("تم تقييم المكالمة من قبل المشرف"));
      }
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default sendSupervisorEvaluation;
