import React from "react";
import "./loader.css";
import { Spinner } from "react-bootstrap";

const LoaderPage = () => {
  return (
    <div className="fallback-spinner">
      <div className="loading">
        <Spinner color="primary" />
      </div>
    </div>
  );
};

export default LoaderPage;
