import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getAllNotification = createAsyncThunk(
  "notification/getAllNotification",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get("/evaluations/evaluation/delay");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default getAllNotification;
