import { Outlet } from "react-router-dom";
import Navbar from "./components/quailty-ui/navbar/Navbar";
import MenuSidebar from "./components/quailty-ui/sidebar/Menu_Sidebar";
import "./App.css";

function App() {
  return (
    <div
      className="page-wrapper "
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <MenuSidebar />
      <div className="body-wrapper">
        <Navbar />
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
