import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getAllNotViolationCalls = createAsyncThunk(
  "supervisor/getAllNotViolationCalls",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get(
        `/evaluations/evaluator/violation/invalid/${id}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default getAllNotViolationCalls;
