import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchAllEmployees = createAsyncThunk(
  "employee/fetchAllEmployees",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get("/users");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default fetchAllEmployees;
