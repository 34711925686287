import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getSingleCallDetails = createAsyncThunk(
  "call/getSingleCallDetails",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get(`/evaluations/evaluation/${id}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default getSingleCallDetails;
