/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetUser } from "../../../store/auth/logIn";
import profile from "../../../images/user-1.jpg";
const Navbar = () => {
  //const { data } = useSelector((state) => state.notificationSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    dispatch(resetUser());
    navigate("/");
  };

  const requiredRoles = ["System Admin", "Quality Dept. Manager"];
  return (
    <header className="app-header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div
          className="navbar-collapse justify-content-end px-0"
          id="navbarNav"
        >
          <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-icon-hover"
                id="drop2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ri-notification-2-line"></i>
                <div className="notification bg-primary rounded-circle"></div>
              </a>
              <div
                className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                aria-labelledby="drop2"
              >
                <div className="d-flex align-items-center justify-content-between py-3 px-7">
                  <h5 className="mb-0 fs-5 fw-semibold">الاشعارات</h5>
                  <span className="badge bg-primary rounded-4 px-3 py-1 lh-sm">
                    5 جديدة
                  </span>
                </div>
                <div className="message-body" data-simplebar="init">
                  <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                      <div
                        className="simplebar-offset"
                        style={{ right: "0px", bottom: "0px" }}
                      >
                        <div
                          className="simplebar-content-wrapper"
                          tabIndex="0"
                          role="region"
                          aria-label="scrollable content"
                          style={{ height: "auto", overflow: "hidden" }}
                        >
                          <div
                            className="simplebar-content"
                            style={{ padding: "0px" }}
                          >
                            <Link className="py-6 px-7 d-flex align-items-center dropdown-item">
                              <div className="w-75 d-inline-block v-middle">
                                <h6 className="mb-1 fw-semibold">
                                  Roman Joined the Team!
                                </h6>
                                <span className="d-block">
                                  Congratulate him
                                </span>
                              </div>
                            </Link>
                            <Link className="py-6 px-7 d-flex align-items-center dropdown-item">
                              <div className="w-75 d-inline-block v-middle">
                                <h6 className="mb-1 fw-semibold">
                                  New message
                                </h6>
                                <span className="d-block">
                                  Salma sent you new message
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="simplebar-placeholder"
                      style={{ width: "0px", height: "0px" }}
                    ></div>
                  </div>
                  <div
                    className="simplebar-track simplebar-horizontal"
                    style={{ visibility: "hidden" }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{ width: "0px", display: "none" }}
                    ></div>
                  </div>
                  <div
                    className="simplebar-track simplebar-vertical"
                    style={{ visibility: "hidden" }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{
                        height: "0px",
                        display: "none",
                        transform: "translate3d(0px, 0px, 0px)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-icon-hover"
                href=""
                id="drop2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={profile}
                  alt=""
                  width="35"
                  height="35"
                  className="rounded-circle"
                />
              </a>

              <div
                className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                aria-labelledby="drop2"
              >
                <div className="message-body ">
                  {requiredRoles.includes(localStorage.getItem("role")) && (
                    <>
                      <Link
                        to="/dashboard/all-employees"
                        className="d-flex hover  align-items-center gap-2 dropdown-item"
                      >
                        <i className="ti ri-team-line"></i>
                        <span className="mb-0 fs-3">الموظفين</span>
                      </Link>

                      <Link
                        to="/dashboard/all-extensions"
                        className="d-flex  align-items-center gap-2 dropdown-item"
                      >
                        <i className="ti ri-user-add-line"></i>
                        <p className="mb-0 fs-3">التحويلات</p>
                      </Link>

                      <Link
                        to="/dashboard/all-departments"
                        className="d-flex  align-items-center gap-2 dropdown-item"
                      >
                        <i className="ti ri-community-line"></i>
                        <p className="mb-0 fs-3">الأقسام</p>
                      </Link>
                    </>
                  )}

                  <Link
                    onClick={signOut}
                    className="btn btn-outline-primary mx-3 mt-2 d-block"
                  >
                    تسجيل خروج
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
export default Navbar;
