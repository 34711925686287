import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const addDepartment = createAsyncThunk(
  "department/addDepartment",
  async (departmentDetails, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `/departments/store`,
        data: {
          name: departmentDetails.department,
          form_type: departmentDetails.scenario,
        },
      });
      if (data.status === 200) {
        dispatch(setMessage("تم اضافة القسم بنجاح"));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors.name[0]);
    }
  }
);

export default addDepartment;
