import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import EditDepartment from "../../../quailty-ui/department/EditDepartment";
import withGuard from "../../../utils/withGurard";

const EditDepartments = () => {
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="جميع الأقسام"
        dashboard="لوحة التحكم"
        breadcrumb="تعديل قسم"
      />
      <EditDepartment />
    </>
  );
};

export default withGuard(EditDepartments);
