import React from "react";

const SupervisorActionCall = ({ violation }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row align-items-start">
          <div className="col-8">
            <h5 className="card-title p-2 fw-semibold d-flex align-items-center ">
              تفاصيل تقييم المشرف
            </h5>
            <p>{violation?.supervisor_id ? violation.supervisor_id : '--'}</p>
          </div>

          <div className="col-4">
            <div className="d-flex justify-content-end">
              {violation?.is_violation_supervisor === null && (
                <div className="text-white bg-info rounded  p-2 d-flex align-items-center justify-content-center">
                  في انتظار التقييم
                </div>
              )}
              {violation?.is_violation_supervisor === "confirmed" && (
                <div className="text-white bg-danger rounded  p-2 d-flex align-items-center justify-content-center">
                  معتمد
                </div>
              )}
              {violation?.is_violation_supervisor === "not_confirmed" && (
                <div className="text-white bg-success rounded  p-2 d-flex align-items-center justify-content-center">
                  غير معتمد
                </div>
              )}
              {violation?.is_violation_supervisor === "warning" && (
                <div className="text-white bg-warning rounded  p-2 d-flex align-items-center justify-content-center">
                  تنبيه
                </div>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12 mt-2">
            <h6 className="fw-semibold mb-3"> الملاحظات :</h6>
          </div>
          <div className="col-md-10 offset-1">
            <p className="fs-3 mb-3">
              {violation?.supervisor_note
                ? violation?.supervisor_note
                : "لا يوجد ملاحظات"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupervisorActionCall;
