import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CallSearchPage from "./components/pages/call_search_page/Call_Search_Page";
import Kpis from "./components/pages/kpis/Kpis";
import { Provider } from "react-redux";
import store from "./store";
import "./API/axios-global";
import ErrorPage from "./components/pages/errorPage/ErrorPage";
import Login from "./components/pages/login_page/Login";
import SupervisorUnvalidCallDetailsPage from "./components/pages/call _supervisor_page/supervisor_unvalid_calls_page/SupervisorUnvalidCallDetailsPage";
import SupervisorValidCallsPage from "./components/pages/call _supervisor_page/supervisor_valid_calls_page/SupervisorValidCallsPage";
import SupervisorValidCallDetailsPage from "./components/pages/call _supervisor_page/supervisor_valid_calls_page/SupervisorValidCallDetailsPage";
import AuditorUnvalidCallsPage from "./components/pages/call_auditor_page/auditor_unvalid_call_page/AuditorUnvalidCallsPage";
import SupervisorUnvalidCallsPage from "./components/pages/call _supervisor_page/supervisor_unvalid_calls_page/SupervisorUnvalidCallsPage";
import AuditorUnvalidCallDetailsPage from "./components/pages/call_auditor_page/auditor_unvalid_call_page/AuditorUnvalidCallDetailsPage";
import AllEmployees from "./components/pages/user_system/employees/AllEmployees";
import AllDepartments from "./components/pages/user_system/departments/AllDepartments";
import AddDepartments from "./components/pages/user_system/departments/AddDepartments";
import EditDepartments from "./components/pages/user_system/departments/EditDepartments";
import AddEmployess from "./components/pages/user_system/employees/AddEmployess";
import AllExtensions from "./components/pages/user_system/all_extensions/AllExtensions";
import EditExtensions from "./components/pages/user_system/all_extensions/EditExtensions";
import AddExtensions from "./components/pages/user_system/all_extensions/AddExtensions";
import EditEmployees from "./components/pages/user_system/employees/EditEmployees";
import GuardedRoute from "./components/utils/GuardedRoute";
import EvaluatorUnvalidCallsPage from "./components/pages/call_evaluator_page/evaluator_unvalid_calls/EvaluatorUnvalidCallsPage";
import EvaluatorValidCallsPage from "./components/pages/call_evaluator_page/evaluator_valid_calls/EvaluatorValidCallsPage";
//import EvaluatorUnvalidCallsDetailsPage from "./components/pages/call_evaluator_page/evaluator_unvalid_calls/EvaluatorUnvalidCallsDetailsPage";
import EvaluatorValidCallsDetailsPage from "./components/pages/call_evaluator_page/evaluator_valid_calls/EvaluatorValidCallsDetailsPage";
//import AllCalls from "./components/pages/all_calls/AllCalls";
import Loadable from "./components/quailty-ui/Loading_page/Loadable";
import { lazy } from "react";
import CallDetailsPage from "./components/pages/all_calls/CallDetailsPage";

const AllCalls = Loadable(
  lazy(() => import("./components/pages/all_calls/AllCalls"))
);

const EvaluatorUnvalidCallsDetailsPage = Loadable(
  lazy(() =>
    import(
      "./components/pages/call_evaluator_page/evaluator_unvalid_calls/EvaluatorUnvalidCallsDetailsPage"
    )
  )
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Kpis /> },
      {
        path: "/dashboard/all-calls",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Quality Dept. Manager",
              "Employee Supervisor",
              "Quality Auditor",
            ]}
          >
            <AllCalls />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/all-calls/call-details/:id",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Quality Dept. Manager",
              "Employee Supervisor",
              "Quality Auditor",
            ]}
          >
            <CallDetailsPage />
         </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/search-calls",
        element: (
          <GuardedRoute
            userRoles={["System Admin", "Evaluator", "Quality Dept. Manager"]}
          >
            <CallSearchPage />
           </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/evaluator-unvalid-calls",
        element: (
          <GuardedRoute
            userRoles={["System Admin", "Evaluator", "Quality Dept. Manager"]}
          >
            <EvaluatorUnvalidCallsPage />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/evaluator-unvalid-calls/call-details/:id",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Employee Supervisor",
              "Quality Dept. Manager",
            ]}
          >
            <EvaluatorUnvalidCallsDetailsPage />
           </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/evaluator-valid-calls",
        element: (
          <GuardedRoute
            userRoles={["System Admin", "Evaluator", "Quality Dept. Manager"]}
          >
            <EvaluatorValidCallsPage />
           </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/evaluator-valid-calls/call-details/:id",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Employee Supervisor",
              "Quality Dept. Manager",
            ]}
          >
            <EvaluatorValidCallsDetailsPage />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/supervisor-unvalid-calls",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Employee Supervisor",
              "Quality Dept. Manager",
            ]}
          >
            <SupervisorUnvalidCallsPage />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/supervisor-unvalid-calls/call-details/:id",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Employee Supervisor",
              "Quality Dept. Manager",
            ]}
          >
            <SupervisorUnvalidCallDetailsPage />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/supervisor-valid-calls",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Employee Supervisor",
              "Quality Dept. Manager",
            ]}
          >
            <SupervisorValidCallsPage />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/supervisor-valid-calls/call-details/:id",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Employee Supervisor",
              "Quality Dept. Manager",
            ]}
          >
            <SupervisorValidCallDetailsPage />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/auditor-unvalid-calls",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Quality Auditor",
              "Quality Dept. Manager",
            ]}
          >
            <AuditorUnvalidCallsPage />
           </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/auditor-unvalid-calls/call-details/:id",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Quality Auditor",
              "Quality Dept. Manager",
            ]}
          >
            <AuditorUnvalidCallDetailsPage />
           </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/all-employees",
        element: (
          <GuardedRoute
            userRoles={[
              "System Admin",
              "Quality Dept. Manager",
              "Employee Supervisor",
            ]}
          >
            <AllEmployees />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/add-employees",
        element: (
          <GuardedRoute userRoles={["System Admin", "Quality Dept. Manager"]}>
            <AddEmployess />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/edit-employees/:id",
        element: (
          <GuardedRoute userRoles={["System Admin", "Quality Dept. Manager"]}>
            <EditEmployees />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/all-departments",
        element: (
          <GuardedRoute userRoles={["System Admin", "Quality Dept. Manager"]}>
            <AllDepartments />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/add-department",
        element: (
          <GuardedRoute userRoles={["System Admin", "Quality Dept. Manager"]}>
            <AddDepartments />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/edit-department/:id",
        element: (
          <GuardedRoute userRoles={["System Admin", "Quality Dept. Manager"]}>
            <EditDepartments />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/all-extensions",
        element: (
          <GuardedRoute userRoles={["System Admin", "Quality Dept. Manager"]}>
            <AllExtensions />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/add-extensions",
        element: (
          <GuardedRoute userRoles={["System Admin", "Quality Dept. Manager"]}>
            <AddExtensions />
          </GuardedRoute>
        ),
      },
      {
        path: "/dashboard/edit-extensions/:id",
        element: (
          <GuardedRoute userRoles={["System Admin", "Quality Dept. Manager"]}>
            <EditExtensions />
          </GuardedRoute>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
