import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const editDepartment = createAsyncThunk(
  "department/editDepartment",
  async (department, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios({
        method: "post",
        url: `/departments/update/${department.id}`,
        data: {
          name: department.name,
          form_type: department.form_type,
        },
      });
      if (data.status === 200) {
        dispatch(setMessage("تم تعديل القسم بنجاح"));
      }
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default editDepartment;
