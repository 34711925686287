import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fetchSingleEmployee from "../../../store/employees/actGetsingelEmployee";
import editEmployee from "../../../store/employees/actEditEmployee";
import fetchSingleDepartment from "../../../store/department/actGetSingleDepartment";
import {
  fetchAllDepartments,
  resetSingleDepartment,
} from "../../../store/department/departmentSlice";
import { MultiSelect } from "react-multi-select-component";
import * as Yup from "yup";

const formSchema = Yup.object().shape({
  email: Yup.string().required("هذا الحقل مطلوب!"),
  role: Yup.string().required("هذا الحقل مطلوب!"),
  //department_id: Yup.string().required("هذا الحقل مطلوب!"),
  employee_id: Yup.string().required("هذا الحقل مطلوب!"),
});

const EditEmployee = () => {
  const { employee, loading } = useSelector((state) => state.employeeSlice);
  const { data, department } = useSelector((state) => state.departmentSlice);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSingleEmployee(id))
      .unwrap()
      .then((res) => {
        dispatch(fetchAllDepartments());

        if (res.department_id === null) return;
        dispatch(fetchSingleDepartment(res.department_id));
      });
  }, [dispatch, id]);

  useEffect(() => {
    if (data) {
      const theRoles = data.map((role) => ({
        label: role.name,
        value: role.id,
      }));
      setOptions(theRoles);
    }
    if (employee) {
      const theOld = employee?.departments?.map((department) => ({
        label: department.name,
        value: department.id,
      }));
      setSelected(theOld);
    } else {
      setSelected([]);
    }
  }, [data, employee]);

  useEffect(() => {
    return () => {
      dispatch(resetSingleDepartment());
    };
  }, [dispatch]);
  const formik = useFormik({
    initialValues: {
      email: employee ? employee?.email : "",
      role: employee ? employee?.role : "",
      department_id: department ? department.id : "",
      employee_id: employee ? employee.employee_number : "",
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      let arr = [];
      selected.map((val) => {
        return arr.push(val.value);
      });
      dispatch(
        editEmployee({
          id: employee?.id,
          email: values.email,
          role: values.role,
          department_id: arr,
          employee_number: values.employee_id,
        })
      )
        .unwrap()
        .then(() => navigate("/dashboard/all-employees"));
    },
  });
  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body p-4">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-4">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">
                    البريد الإلكتروني
                  </Form.Label>
                  <Form.Control
                    type="email"
                    className="form-control  ps-2"
                    placeholder="ادخل البريد الالكتروني"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    isInvalid={!!formik.errors.email}
                    isValid={formik.touched.email && !formik.errors.email}
                    onBlur={formik.handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="">
                <Form.Label>الدور</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="role"
                  onChange={formik.handleChange}
                  value={formik.values.role}
                  isInvalid={!!formik.errors.role}
                  isValid={formik.touched.role && !formik.errors.role}
                  onBlur={formik.handleBlur}
                >
                  <option>اختر القسم</option>
                  <option value="System Admin">مسئول النظام</option>
                  <option value="Evaluator">المقيم</option>
                  <option value="Quality Dept. Manager">مدير قسم الجودة</option>
                  <option value="Employee Supervisor">مشرف الموظف</option>
                  <option value="Quality Auditor">مدقق الجودة</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.role}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="form-group mb-3">
                <Form.Label className="form-label">الرقم الوظيفي</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control  ps-2"
                  placeholder="ادخل الرقم الوظيفي"
                  name="employee_id"
                  onChange={formik.handleChange}
                  value={formik.values.employee_id}
                  isInvalid={!!formik.errors.employee_id}
                  isValid={
                    formik.touched.employee_id && !formik.errors.employee_id
                  }
                  onBlur={formik.handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.employee_id}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            {formik.values.role === "Employee Supervisor" && (
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>اسم القسم</Form.Label>
                  <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                    name="department_id"
                  />
                  {/* <Form.Select
                    aria-label="Default select example"
                    name="department_id"
                    onChange={formik.handleChange}
                    value={formik.values.department_id}
                    isInvalid={!!formik.errors.department_id}
                    isValid={
                      formik.touched.department_id &&
                      !formik.errors.department_id
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option>اختر القسم</option>
                    {data.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                  </Form.Select> */}
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.department_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-success w-100"
            disabled={loading || !(formik.isValid && formik.dirty)}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <>تعديل</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditEmployee;
