import React from "react";
import withGuard from "../../utils/withGurard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import getAllNotification from "../../../store/notification/actGetAllNotification";

const Kpis = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("role") === "Quality Auditor") {
      dispatch(getAllNotification());
    }
  }, [dispatch]);
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">KPIS Page</h5>
        <p className="mb-0">قيد الانشاء</p>
      </div>
    </div>
  );
};

export default withGuard(Kpis);
