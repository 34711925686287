import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: null,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    closeNotification: (state) => {
      state.message = null;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { closeNotification, setMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
