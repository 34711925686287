import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import useSupervisorUnvalidCallsDataTable from "../../../hooks/supervisor_datatable/use-supervisor-unvalid-call-datatable";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import withGuard from "../../../utils/withGurard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import getAllViolationCalls from "../../../../store/supervisor/actGetAllViolationCalls";
import { resetData } from "../../../../store/supervisor/supervisorSlice";
import Loader from "../../../quailty-ui/loader/Loader";
import MessageNotification from "../../../hooks/messages/MessageNotification";

const SupervisorUnvalidCallsPage = () => {
  const { message } = useSelector((state) => state.messagesSlice);
  const dispatch = useDispatch();
  //user id
  const id = localStorage.getItem("id");

  useEffect(() => {
    dispatch(getAllViolationCalls(id));
  }, [dispatch, id]);

  const {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    loading,
  } = useSupervisorUnvalidCallsDataTable();

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, [dispatch]);
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="صفحة المكالمات المخالفة"
        dashboard="لوحة التحكم"
        breadcrumb="المكالمات المخالفة"
      />
      {message && <MessageNotification message={message} />}
      <div className="card">
        <div className="card-body">
          <DataTableExtensions {...tableData} filterPlaceholder="بحث">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              highlightOnHover
              pointerOnHover
              customStyles={customStyles}
              defaultSortFieldId={1}
              persistTableHead
              noDataComponent={
                <span className="p-5">لا توجد بيانات لعرضها</span>
              }
              noContextMenu
              selectableRows
              selectableRowsHighlight
              progressPending={loading}
              progressComponent={<Loader />}
            />
          </DataTableExtensions>
        </div>
      </div>
    </>
  );
};

export default withGuard(SupervisorUnvalidCallsPage);
