import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAuditorUnvalidCallsDataTable = () => {
  const { data, loading } = useSelector((state) => state.auditorSlice);
  const navigate = useNavigate();
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#f1f5f9",
        fontWeight: "bolder",
        textAlign: "center",
      },
    },
    TableHeadRow: {
      style: {
        backgroundColor: "#f1f5f9",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center !important",
      },
    },
  };

  const columns = [
    {
      name: "ID المكالمة",
      selector: (row) => row.call?.uid,
      sortable: true,
      cellExport: (row) => row.call?.uid,
    },
    {
      name: "اسم الموظف",
      selector: (row) => row.extension?.name,
      sortable: true,
      cellExport: (row) => row.extension?.name,
    },
    {
      name: "القسم",
      selector: (row) => row.call.department?.name,
      sortable: true,

      cellExport: (row) => row.call.department?.name,
    },

    {
      name: "المكالمة من",
      selector: (row) => row.call.call_from,
      sortable: true,
      cellExport: (row) => row.call.call_from,
    },
    {
      name: "المكالمة الي",
      selector: (row) => row.call.call_to,
      sortable: true,
      cellExport: (row) => row.call.call_to,
    },
    {
      name: "حالة المكالمة",
      selector: (row) => (row.call.status === "Outbound" ? "صادرة" : "واردة"),
      sortable: true,
      cellExport: (row) => (row.call.status === "Outbound" ? "صادرة" : "واردة"),
    },
    {
      name: "تاريخ التقييم",
      selector: (row) => row.created_at.substring(10, -row.created_at.length),
      sortable: true,
      cellExport: (row) => row.created_at.substring(10, -row.created_at.length),
    },
    {
      name: "الاجراءات",
      selector: (row) => row.id,
      sortable: true,
      cell: (d) => (
        <button
          onClick={() =>
            navigate(`/dashboard/auditor-unvalid-calls/call-details/${d.id}`)
          }
          className="btn btn-primary"
        >
          التفاصيل
        </button>
      ),
      cellExport: (row) => row.details,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const paginationComponentOptions = {
    rowsPerPageText: " عرض الإدخالات",
    rangeSeparatorText: "إظهار من أصل مُدخلات",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  return {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    loading,
  };
};

export default useAuditorUnvalidCallsDataTable;
