import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const deleteDepartment = createAsyncThunk(
  "department/deleteDepartment",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios.get(`/departments/remove/${id}`);
      if (data.status === 200) {
        dispatch(setMessage("تم حذف القسم بنجاح"));
      }
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default deleteDepartment;
