import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchCallDetails = createAsyncThunk(
  "call/fetchCallDetails",
  async (extension, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get(
        `extensions/extension/route/${extension}`
      );
      if (data.status === 404) {
        return rejectWithValue(
          "هذه التحويلة غير موجودة بالنظام برجاء اضافة كافة المعلومات بشكل صحيح"
        );
      }
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default fetchCallDetails;
