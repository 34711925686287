import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const sendAuditorEvaluation = createAsyncThunk(
  "auditor/sendAuditorEvaluation",
  async (AuditorEvaluation, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `/evaluations/store/auditor/${AuditorEvaluation.id}`,
        data: {
          auditor_id: AuditorEvaluation.auditor_id,
          is_violation_auditor: AuditorEvaluation.is_violation_auditor,
          auditor_note: AuditorEvaluation.auditor_note,
        },
      });
      if (data.status === 200) {
        dispatch(setMessage("تم تقييم المكالمة من قبل المدقق"));
      }
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default sendAuditorEvaluation;
