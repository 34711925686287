/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { NavLink } from "react-router-dom";
import logo from "../../../../images/naqiLogo.png";

/* eslint-disable react/style-prop-object */
const AdminSidebar = () => {
  return (
    <aside className="left-sidebar">
      <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
          <NavLink
            to="/dashboard"
            style={{
              display: "inline-block",
              margin: "auto",
              textAlign: "center",
            }}
            className="text-nowrap logo-img"
          >
            <img src={logo} width="80" alt="" />
          </NavLink>
          <div
            className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
            id="sidebarCollapse"
          >
            <i className="ti ti-x fs-8"></i>
          </div>
        </div>
        <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
          <ul id="sidebarnav">
            <li className="sidebar-item mt-4">
              <NavLink className="sidebar-link" to="/dashboard" end>
                <span>
                  <i className="ti ri-dashboard-line"></i>
                </span>
                <span className="hide-menu">لوحة التحكم</span>
              </NavLink>
            </li>

            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu" style={{ fontSize: "17px" }}>
                اجراءات التقييم
              </span>
            </li>
            <li className="sidebar-item">
              <NavLink className="sidebar-link" to="/dashboard/search-calls">
                <span>
                  <i className="ti ri-headphone-line"></i>
                </span>
                <span className="hide-menu">تقييم المكالمة</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                to="/dashboard/evaluator-unvalid-calls"
              >
                <span>
                  <i className="ti ri-terminal-window-fill"></i>
                </span>
                <span className="hide-menu">المكالمة المخالفة</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                to="/dashboard/evaluator-valid-calls"
              >
                <span>
                  <i className="ti ri-terminal-window-line"></i>
                </span>
                <span className="hide-menu">المكالمات الصحيحة</span>
              </NavLink>
            </li>

            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu" style={{ fontSize: "17px" }}>
                مشرف الموظف
              </span>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                to="/dashboard/supervisor-unvalid-calls"
                aria-expanded="false"
              >
                <span>
                  <i className="ti ri-terminal-window-line"></i>
                </span>
                <span className="hide-menu">المكالمات المخالفة</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                to="/dashboard/supervisor-valid-calls"
                aria-expanded="false"
              >
                <span>
                  <i className="ti ri-terminal-window-line"></i>
                </span>
                <span className="hide-menu">المكالمات الصحيحه</span>
              </NavLink>
            </li>

            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu" style={{ fontSize: "17px" }}>
                مدقق الجودة النهائي
              </span>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                to="/dashboard/auditor-unvalid-calls"
                aria-expanded="false"
              >
                <span>
                  <i className="ti ri-terminal-window-fill"></i>
                </span>
                <span className="hide-menu">المكالمات المخالفة</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                to="/dashboard/all-calls"
                aria-expanded="false"
              >
                <span>
                  <i className="ti ri-terminal-window-line"></i>
                </span>
                <span className="hide-menu">جميع المكالمات</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default AdminSidebar;
