import { createSlice } from "@reduxjs/toolkit";
import getEvaluatorAllViolationCalls from "./actGetViolationCalls";
import getEvaluatorAllUnViolationCalls from "./actGetUnViolationCalls";
import getEvaluatorSingleCallDetails from "./actGetSingleCallDetails";

const initialState = {
  data: [],
  error: null,
  loading: false,
  callDetails: null,
};

const evaluatorSlice = createSlice({
  name: "evaluator",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
      state.singleViolation = null;
    },
    resetCallDetails: (state) => {
      state.callDetails = null;
    },
  },
  extraReducers: (builder) => {
    // ========================== get All Violation Calls from server ==========================
    builder.addCase(getEvaluatorAllViolationCalls.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEvaluatorAllViolationCalls.fulfilled,
      (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      }
    );
    builder.addCase(getEvaluatorAllViolationCalls.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== get All unViolation Calls from server ==========================
    builder.addCase(getEvaluatorAllUnViolationCalls.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEvaluatorAllUnViolationCalls.fulfilled,
      (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      }
    );
    builder.addCase(
      getEvaluatorAllUnViolationCalls.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
    // ========================== get Single Call Details from server ==========================
    builder.addCase(getEvaluatorSingleCallDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEvaluatorSingleCallDetails.fulfilled,
      (state, action) => {
        state.loading = false;
        state.callDetails = action.payload;
      }
    );
    builder.addCase(getEvaluatorSingleCallDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetData, resetCallDetails } = evaluatorSlice.actions;
export default evaluatorSlice.reducer;
