import React from "react";
import { useNavigate } from "react-router-dom";
// import ModalDepartment from "../../pages/user_system/departments/ModalDepartment";
import { useSelector } from "react-redux";

const UseDepartments = () => {
  const { data, loading } = useSelector((state) => state.departmentSlice);
  const navigate = useNavigate();

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#f1f5f9",
        fontWeight: "bolder",
        textAlign: "center",
      },
    },
    TableHeadRow: {
      style: {
        backgroundColor: "#f1f5f9",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center !important",
      },
    },
  };

  const columns = [
    {
      name: "القسم",
      selector: (row) => (row.name ? row?.name : ""),
      sortable: true,
      cell: (d) => (d.name ? d?.name : ""),
    },
    {
      name: "الاجراءات",
      selector: (row) => row?.id,
      cell: (d) => (
        <>
          <button
            onClick={() => navigate(`/dashboard/edit-department/${d.id}`)}
            className="m-2 btn btn-success"
          >
            <i className="ti ri-edit-box-line"></i>
          </button>
          {/* <ModalDepartment department={d} /> */}
        </>
      ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: " عرض الإدخالات",
    rangeSeparatorText: "إظهار من أصل مُدخلات",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  const tableData = {
    columns,
    data,
  };
  return {
    customStyles,
    paginationComponentOptions,
    columns,
    tableData,
    loading,
    data,
  };
};

export default UseDepartments;
