import { configureStore } from "@reduxjs/toolkit";
import callSlice from "./call/callSlice";
import departmentSlice from "./department/departmentSlice";
import extensionSlice from "./allExtension/extensionSlice";
import employeeSlice from "./employees/employees";
import logIn from "./auth/logIn";
import supervisorSlice from "./supervisor/supervisorSlice";
import auditorSlice from "./auditor/auditorSlice";
import notificationSlice from "./notification/notificationSlice";
import evaluatorSlice from "./evaluator/evaluatorSlice";
import messagesSlice from "./messages/messagesSlice";

const store = configureStore({
  reducer: {
    logIn,
    callSlice,
    departmentSlice,
    extensionSlice,
    employeeSlice,
    supervisorSlice,
    auditorSlice,
    notificationSlice,
    evaluatorSlice,
    messagesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
