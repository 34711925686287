import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import addExtension from "../../../store/allExtension/actAddExtension";
import { useEffect } from "react";
import fetchAllDepartments from "../../../store/department/actGetAllDepartments";
import { Form } from "react-bootstrap";
import Error from "../error/Error";
import * as Yup from "yup";

const formSchema = Yup.object().shape({
  name: Yup.string().required("هذا الحقل مطلوب!"),
  route: Yup.string().required("هذا الحقل مطلوب!"),
  department_id: Yup.string().required("هذا الحقل مطلوب!"),
  employee_id: Yup.string().required("هذا الحقل مطلوب!"),
});
const AddExtension = () => {
  const { data } = useSelector((state) => state.departmentSlice);
  const { loading, error } = useSelector((state) => state.extensionSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllDepartments());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      name: "",
      route: "",
      department_id: "",
      employee_id: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      dispatch(
        addExtension({
          name: values.name,
          route: values.route,
          department_id: values.department_id,
          employee_number: values.employee_id,
        })
      )
        .unwrap()
        .then(() => navigate("/dashboard/all-extensions"))
        .catch((error) => {});
    },
  });

  return (
    <>
      {error && <Error error={error} />}
      <div className="card">
        <form onSubmit={formik.handleSubmit}>
          <div className="card-body p-4">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">الاسم </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control  ps-2"
                    placeholder="ادخل اسم الموظف"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!formik.errors.name}
                    isValid={formik.touched.name && !formik.errors.name}
                    onBlur={formik.handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">رقم التحويلة</Form.Label>
                  <Form.Control
                    type="number"
                    className="form-control  ps-2"
                    placeholder="ادخل رقم التحويلة"
                    name="route"
                    onChange={formik.handleChange}
                    value={formik.values.route}
                    isInvalid={!!formik.errors.route}
                    isValid={formik.touched.route && !formik.errors.route}
                    onBlur={formik.handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.route}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>اسم القسم</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="department_id"
                    onChange={formik.handleChange}
                    value={formik.values.department_id}
                    isInvalid={!!formik.errors.department_id}
                    isValid={
                      formik.touched.department_id &&
                      !formik.errors.department_id
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option>اختر القسم</option>
                    {data.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.department_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">الرقم الوظيفي</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control  ps-2"
                    placeholder="ادخل الرقم الوظيفي"
                    name="employee_id"
                    onChange={formik.handleChange}
                    value={formik.values.employee_id}
                    isInvalid={!!formik.errors.employee_id}
                    isValid={
                      formik.touched.employee_id && !formik.errors.employee_id
                    }
                    onBlur={formik.handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.employee_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={loading}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                <>أضف</>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddExtension;
