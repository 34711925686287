import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import useAuditorUnvalidCallsDataTable from "../../../hooks/auditor_datatable/use-auditor-unvalid-call-datatable";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import withGuard from "../../../utils/withGurard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import getauditorAllViolationCalls from "../../../../store/auditor/actGetAllViolationCalls";
import Loader from "../../../quailty-ui/loader/Loader";
import MessageNotification from "../../../hooks/messages/MessageNotification";

const AuditorUnvalidCallsPage = () => {
  const { message } = useSelector((state) => state.messagesSlice);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getauditorAllViolationCalls());
  }, [dispatch]);

  const {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    loading,
  } = useAuditorUnvalidCallsDataTable();

  return (
    <>
      <Breadcrumb
        breadcrumbHeading="صفحة المكالمات المخالفة"
        dashboard="لوحة التحكم"
        breadcrumb="المكالمات المخالفة"
      />
      {message && <MessageNotification message={message} />}

      <div className="card">
        <div className="card-body">
          <DataTableExtensions {...tableData} filterPlaceholder="بحث">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              highlightOnHover
              pointerOnHover
              customStyles={customStyles}
              defaultSortFieldId={1}
              persistTableHead
              noDataComponent={
                <span className="p-5">لا توجد بيانات لعرضها</span>
              }
              noContextMenu
              selectableRows
              selectableRowsHighlight
              progressPending={loading}
              progressComponent={<Loader />}
            />
          </DataTableExtensions>
        </div>
      </div>
    </>
  );
};

export default withGuard(AuditorUnvalidCallsPage);
