import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import ModalExtensions from "../../quailty-ui/all_extensions/ModalExtensions";

const UseExtensions = () => {
  const { data, loading } = useSelector((state) => state.extensionSlice);
  const navigate = useNavigate();
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#f1f5f9",
        fontWeight: "bolder",
        textAlign: "center",
      },
    },
    TableHeadRow: {
      style: {
        backgroundColor: "#f1f5f9",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center !important",
      },
    },
  };

  const columns = [
    {
      name: "اسم الموظف",
      selector: (row) => (row.name ? row?.name : ""),
      sortable: true,
      cell: (d) => (d.name ? d?.name : ""),
      cellExport: (row) => row.name,
    },
    {
      name: "القسم",
      selector: (row) => row?.department?.name,
      sortable: true,
      cell: (d) => d?.department?.name,
      cellExport: (row) => row.department?.name,
    },

    {
      name: "رقم التحويلة",
      selector: (row) => (row.route ? row?.route : "--"),
      sortable: true,
      cellExport: (row) => (row.route ? row?.route : "--"),
    },
    {
      name: "الرقم الوظيفي",
      selector: (row) => (row.employee_number ? row.employee_number : "--"),
      sortable: true,
      cell: (d) => (d.employee_number ? d.employee_number : "--"),
      cellExport: (row) => row.employee_number,
    },
    {
      name: "الاجراءات",
      selector: (row) => row?.id,
      sortable: true,
      cell: (d) => (
        <>
          <button
            onClick={() => navigate(`/dashboard/edit-extensions/${d.id}`)}
            className="m-2 btn btn-success"
          >
            <i className="ti ri-edit-box-line"></i>
          </button>
          {/* <ModalExtensions extention={d} /> */}
        </>
      ),
      cellExport: (row) => row.details,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const paginationComponentOptions = {
    rowsPerPageText: " عرض الإدخالات",
    rangeSeparatorText: "إظهار من أصل مُدخلات",
    selectAllRowsItem: true,
    selectAllRowsItemText: "الكل",
  };
  return {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    loading,
  };
};

export default UseExtensions;
