import { createSlice } from "@reduxjs/toolkit";
import addEmployee from "./actAddEmployee";
import fetchAllEmployees from "./actGetAllEmployees";
import editEmployee from "./actEditEmployee";
import fetchSingleEmployee from "./actGetsingelEmployee";
import deleteEmployee from "./actDeleteEmployee";

const initialState = {
  data: [],
  error: null,
  loading: false,
  employee: null,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
      state.employee = null;
    },
  },
  extraReducers: (builder) => {
    // ========================== get data from server ==========================
    builder.addCase(fetchAllEmployees.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllEmployees.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(fetchAllEmployees.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== add data to server ==========================
    builder.addCase(addEmployee.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addEmployee.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(addEmployee.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== edit data from server ==========================
    builder.addCase(editEmployee.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(editEmployee.fulfilled, (state, action) => {
      state.loading = false;
      state.employee = action.payload;
    });
    builder.addCase(editEmployee.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== read data from server ==========================
    builder.addCase(fetchSingleEmployee.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSingleEmployee.fulfilled, (state, action) => {
      state.loading = false;
      state.employee = action.payload;
    });
    builder.addCase(fetchSingleEmployee.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== delete data from server ==========================
    builder.addCase(deleteEmployee.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteEmployee.fulfilled, (state, action) => {
      state.loading = false;
      state.data = state.data.filter((el) => el.id !== action.payload.id);
    });
    builder.addCase(deleteEmployee.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export {
  addEmployee,
  fetchAllEmployees,
  fetchSingleEmployee,
  editEmployee,
  deleteEmployee,
};
export const { resetData } = employeeSlice.actions;
export default employeeSlice.reducer;
