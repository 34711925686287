import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalEmployees from "../../quailty-ui/employees/ModalEmployee";

const UseEmployees = () => {
  const { data, loading, error } = useSelector((state) => state.employeeSlice);
  const navigate = useNavigate();
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#f1f5f9",
        fontWeight: "bolder",
        textAlign: "center",
      },
    },
    TableHeadRow: {
      style: {
        backgroundColor: "#f1f5f9",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center !important",
      },
    },
  };

  const columns = [
    {
      name: "البريد الالكتروني",
      selector: (row) => row.email,
      sortable: true,
      cell: (d) => d.email,
      cellExport: (row) => row.email,
    },
    {
      name: "الدور",
      selector: (row) =>
        row.role === "System Admin"
          ? "مسؤول النظام"
          : row.role === "Evaluator"
          ? "المراقب"
          : row.role === "Quality Dept. Manager"
          ? "مدير قسم الجودة"
          : row.role === "Employee Supervisor"
          ? "مشرف الموظف"
          : "مدقق الجودة",
      sortable: true,
      cellExport: (row) => row.role,
    },
    {
      name: "الرقم الوظيفي",
      selector: (row) => (row.employee_number ? row.employee_number : "--"),
      sortable: true,
      cell: (d) => (d.employee_number ? d.employee_number : "--"),
      cellExport: (row) => row.employee_number,
    },
    {
      name: "الاجراءات",
      selector: (row) => row.id,
      sortable: true,
      cell: (d) => (
        <>
          <button
            onClick={() => navigate(`/dashboard/edit-employees/${d.id}`)}
            className="m-2 btn btn-success"
          >
            <i className="ti ri-edit-box-line"></i>
          </button>
          <ModalEmployees employee={d} />
        </>
      ),
      cellExport: (row) => row.details,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const paginationComponentOptions = {
    rowsPerPageText: " عرض الإدخالات",
    rangeSeparatorText: "إظهار من أصل مُدخلات",
    selectAllRowsItem: true,
    selectAllRowsItemText: "الكل",
  };
  return {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    loading,
    error,
  };
};

export default UseEmployees;
