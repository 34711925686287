import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setMessage } from "../messages/messagesSlice";

const addExtension = createAsyncThunk(
  "extension/addExtension",
  async (extension, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `/extensions/store`,
        data: extension,
      });
      if (data.status === 200) {
        dispatch(setMessage("تم اضافة التحويلة بنجاح"));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors.route[0]);
    }
  }
);

export default addExtension;
