import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getauditorAllViolationCalls = createAsyncThunk(
  "auditor/getauditorAllViolationCalls",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get("/evaluations/auditor/evaluations");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export default getauditorAllViolationCalls;
