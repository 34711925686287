import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeNotification } from "../../../store/messages/messagesSlice";
import { Alert } from "react-bootstrap";

const MessageNotification = ({ message }) => {
  const dispatch = useDispatch();

  const closeNotificationHandler = useCallback(() => {
    dispatch(closeNotification());
  }, [dispatch]);

  useEffect(() => {
    if (!message) return;
    const debounce = setTimeout(closeNotificationHandler, 3000);

    return () => {
      clearTimeout(debounce);
    };
  }, [message, closeNotificationHandler]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      closeNotificationHandler();
    });
  }, [closeNotificationHandler]);

  return <Alert variant="primary">{message}</Alert>;
};

export default MessageNotification;
