import React from "react";

const Error = ({ error }) => {
  return (
    <div className="alert alert-danger" role="alert">
      {error === "The uid has already been taken."
        ? "لقد تم تقييم المكالمة من قبل"
        : error}
    </div>
  );
};

export default Error;
