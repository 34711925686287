import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getAllCalls = createAsyncThunk(
  "call/getAllCalls",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get(`evaluations/`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default getAllCalls;
