import { createSlice } from "@reduxjs/toolkit";
import fetchAllDepartments from "./actGetAllDepartments";
import deleteDepartment from "./actDeleteDepartment";
import addDepartment from "./actAddDepartment";
import fetchSingleDepartment from "./actGetSingleDepartment";
import editDepartment from "./actEditDepartment";

const initialState = {
  data: [],
  error: null,
  loading: false,
  department: null,
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
    resetSingleDepartment: (state) => {
      state.department = null;
    },
  },
  extraReducers: (builder) => {
    // ========================== get data from server ==========================
    builder.addCase(fetchAllDepartments.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllDepartments.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(fetchAllDepartments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== add data to server ==========================
    builder.addCase(addDepartment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addDepartment.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(addDepartment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== read data from server ==========================
    builder.addCase(fetchSingleDepartment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSingleDepartment.fulfilled, (state, action) => {
      state.loading = false;
      state.department = action.payload;
    });
    builder.addCase(fetchSingleDepartment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== edit data from server ==========================
    builder.addCase(editDepartment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(editDepartment.fulfilled, (state, action) => {
      state.loading = false;
      state.department = action.payload;
    });
    builder.addCase(editDepartment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ========================== delete data from server ==========================
    builder.addCase(deleteDepartment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteDepartment.fulfilled, (state, action) => {
      state.loading = false;
      state.data = state.data.filter((el) => el.id !== action.payload.id);
    });
    builder.addCase(deleteDepartment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export {
  fetchAllDepartments,
  addDepartment,
  fetchSingleDepartment,
  editDepartment,
  deleteDepartment,
};
export const { resetData, resetSingleDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
