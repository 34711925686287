import React from "react";
import CallDetails from "../../quailty-ui/callDetails/CallDetails";
import EvaluatorUnvalidCallDetails from "../../quailty-ui/callDetails/EvaluatorUnvalidCallDetails";
import SupervisorActionCall from "../../quailty-ui/callDetails/SupervisorActionCall";
import AuditorActionCallDetails from "../../quailty-ui/callDetails/AuditorActionCallDetails";
import { useEffect } from "react";
import getSingleCallDetails from "../../../store/call/actGetSingleCall";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetSingleCall } from "../../../store/call/callSlice";
import Breadcrumb from "../../quailty-ui/breadcrumb/Breadcrumb";
import EvaluatorvalidCallDetails from "../../quailty-ui/callDetails/EvaluatorvalidCallDetails";
import LoaderPage from "../../quailty-ui/Loading_page/LoaderPage";

const CallDetailsPage = () => {
  const { singlecall, loading } = useSelector((state) => state.callSlice);
  const dispatch = useDispatch();
  const { id } = useParams();

  //get the call details
  useEffect(() => {
    dispatch(getSingleCallDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetSingleCall());
    };
  }, [dispatch]);
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="المكالمات المقيمة"
        dashboard="لوحة التحكم"
        breadcrumb="جميع المكالمات / تفاصيل المكالمة"
      />
      {loading ? (
        <LoaderPage />
      ) : (
        <>
          <CallDetails CallDetails={singlecall} />
          <div className="row">
            {singlecall?.is_violation_evaluator ? (
              <>
                <div className="col-md-6">
                  <EvaluatorUnvalidCallDetails violation={singlecall} />
                </div>
                <div className="col-md-6">
                  <SupervisorActionCall violation={singlecall} />
                </div>
                <div className="col-md-12">
                  <AuditorActionCallDetails violation={singlecall} />
                </div>
              </>
            ) : (
              <div className="col-md-6 offset-3">
                <EvaluatorvalidCallDetails violation={singlecall} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CallDetailsPage;
