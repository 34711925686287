import React from "react";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import UseDepartments from "../../../hooks/departments/use-departments";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchAllDepartments from "../../../../store/department/actGetAllDepartments";
import DataTableExtensions from "react-data-table-component-extensions";

import "react-data-table-component-extensions/dist/index.css";
import Loader from "../../../quailty-ui/loader/Loader";
import withGuard from "../../../utils/withGurard";
import { resetData } from "../../../../store/department/departmentSlice";
import MessageNotification from "../../../hooks/messages/MessageNotification";

const AllDepartments = () => {
  const { message } = useSelector((state) => state.messagesSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllDepartments());
  }, [dispatch]);

  const {
    customStyles,
    paginationComponentOptions,
    columns,
    tableData,
    loading,
    data,
  } = UseDepartments();

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, [dispatch]);

  return (
    <>
      <Breadcrumb
        breadcrumbHeading="كل الأقسام"
        dashboard="لوحة التحكم"
        breadcrumb="الأقسام"
      />
      {message && <MessageNotification message={message} />}
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-end mb-3">
            <button
              onClick={() => navigate("/dashboard/add-department")}
              className="btn text-white bg-primary rounded  p-6 d-flex align-items-center justify-content-center"
            >
              أضف قسم +
            </button>
          </div>
          <DataTableExtensions {...tableData} filterPlaceholder="بحث">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              pointerOnHover
              customStyles={customStyles}
              defaultSortFieldId={1}
              persistTableHead
              noDataComponent={
                <span className="p-5">لا توجد بيانات لعرضها</span>
              }
              noContextMenu
              selectableRows
              selectableRowsHighlight
              progressPending={loading}
              progressComponent={<Loader />}
            />
          </DataTableExtensions>
        </div>
      </div>
    </>
  );
};

export default withGuard(AllDepartments);
