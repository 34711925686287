import { useFormik } from "formik";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetData,
  setData,
  setError,
  success,
} from "../../../store/call/callSlice";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import fetchCallDetails from "../../../store/call/actGetCallDetails";

const formSchema = Yup.object().shape({
  call_id: Yup.string()
    .min(19, "تأكد من ادخال هذا الحقل بشكل صحيح")
    .max(19, "تأكد من ادخال هذا الحقل بشكل صحيح")
    .required("هذا الحقل مطلوب!"),
  call_status: Yup.string()
    .min(7, "تأكد من ادخال هذا الحقل بشكل صحيح")
    .required("هذا الحقل مطلوب!"),
  call_date: Yup.string().required("هذا الحقل مطلوب!"),
  call_from: Yup.string().required("هذا الحقل مطلوب!"),
  call_to: Yup.string().required("هذا الحقل مطلوب!"),
  call_duration: Yup.string().required("هذا الحقل مطلوب!"),
});
const SearchCall = () => {
  const [term, setTerm] = useState("");
  const { call, loading, error } = useSelector((state) => state.callSlice);
  const [supError, setsupError] = useState(false);
  const dispatch = useDispatch();

  const inputHandler = useCallback(() => {
    const inputString = term;
    // Trim the white spaces from the beginning and end of the string
    const trimmedString = inputString.trim();
    const dataArray = trimmedString.split(/\t/g);
    // Output: "This is a string with leading and trailing white spaces."

    function containsNonNumbers(str) {
      return /\D/.test(str);
    }

    if (dataArray.length >= 3) {
      //call_from
      if (containsNonNumbers(dataArray[2])) {
        dataArray[2] = dataArray[2].replace(/[^\d]/g, "");
        if (dataArray[2].substring(0, 3) === "966") {
          dataArray[2] = "0" + dataArray[2].substring(3, dataArray[2].length);
        } else {
          dataArray[2] = dataArray[2].substring(0, 10);
        }
      } else {
        dataArray[2] = dataArray[2].substring(0, dataArray[2].length);
      }

      //call_to
      if (containsNonNumbers(dataArray[3])) {
        dataArray[3] = dataArray[3].replace(/[^\d]/g, "");
        if (dataArray[3].substring(0, 3) === "966") {
          dataArray[3] = "0" + dataArray[3].substring(3, dataArray[3].length);
        } else {
          dataArray[3] = dataArray[3].substring(0, 10);
        }
      } else {
        dataArray[3] = dataArray[3].substring(0, dataArray[3].length);
      }
      if (dataArray.length === 7) {
        const lastDataArray = ["Outbound", "Inbound"];
        const searchLastLength = dataArray?.filter((lastLength) =>
          lastDataArray?.includes(lastLength)
        );
        if (dataArray[6] !== searchLastLength.join("")) {
          setsupError(true);
          dispatch(
            setError("البيانات الموجودة بالحقل (حالة المكالمة) غير صحيحة")
          );
        }
      } else {
        setsupError(true);
        dispatch(setError("تأكد من صحة المعلومات المدخلة"));
        //setDataError("تأكد من صحة المعلومات المدخلة");
      }
    }
    dispatch(
      setData({
        call_id: dataArray[0] ? dataArray[0] : "",
        call_date: dataArray[1] ? dataArray[1] : "",
        call_from: dataArray[2] ? dataArray[2] : "",
        call_to: dataArray[3] ? dataArray[3] : "",
        call_duration: dataArray[4] ? dataArray[4] : "",
        call_status: dataArray[6] ? dataArray[6] : "",
      })
    );
  }, [term, dispatch]);

  useEffect(() => {
    if (term) {
      inputHandler();
    } else {
      setTerm("");
      dispatch(resetData());
      dispatch(setError(null));
      dispatch(success(null));
    }
  }, [term, inputHandler, dispatch]);

  const formik = useFormik({
    initialValues: {
      call_id: call ? call?.call_id : "",
      call_date: call ? call?.call_date : "",
      call_from: call ? call?.call_from : "",
      call_to: call ? call?.call_to : "",
      call_duration: call ? call?.call_duration : "",
      call_status: call ? call?.call_status : "",
    },
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      if (values.call_status === "Outbound") {
        dispatch(fetchCallDetails(values.call_from));
        setsupError(false);
      } else {
        dispatch(fetchCallDetails(values.call_to));
        setsupError(false);
      }
    },
  });
  return (
    <>
      {/*dataError && <Error error={dataError} />*/}
      <h5 className="card-title fw-semibold mb-4">ابحث عن المكالمة</h5>
      <div className="card">
        <div className="card-body">
          <div className="mb-5">
            <label className="form-label mb-3">معلومات المكالمة</label>
            <input
              type="text"
              className="form-control"
              placeholder="أدخل معلومات المكالمة"
              value={term}
              onChange={(e) => {
                setsupError(false);
                setTerm(e.target.value);
                dispatch(setError(null));
                dispatch(success(null));
              }}
            />
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">ID المكالمة</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="call_id"
                    onChange={formik.handleChange}
                    value={formik.values.call_id}
                    isInvalid={!!formik.errors.call_id}
                    isValid={formik.touched.call_id && !formik.errors.call_id}
                    onBlur={formik.handleBlur}
                    readOnly={true}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.call_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">وقت المكالمة</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="call_date"
                    onChange={formik.handleChange}
                    value={formik.values.call_date}
                    isInvalid={!!formik.errors.call_date}
                    isValid={
                      formik.touched.call_date && !formik.errors.call_date
                    }
                    onBlur={formik.handleBlur}
                    readOnly={true}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.call_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">المكالمة من</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="call_from"
                    onChange={formik.handleChange}
                    value={formik.values.call_from}
                    isInvalid={!!formik.errors.call_from}
                    isValid={
                      formik.touched.call_from && !formik.errors.call_from
                    }
                    onBlur={formik.handleBlur}
                    readOnly={true}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.call_from}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">المكالمة الي</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="call_to"
                    onChange={formik.handleChange}
                    value={formik.values.call_to}
                    isInvalid={!!formik.errors.call_to}
                    isValid={formik.touched.call_to && !formik.errors.call_to}
                    onBlur={formik.handleBlur}
                    readOnly={true}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.call_to}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-4">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label"> مدة المكالمة</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="call_duration"
                    onChange={formik.handleChange}
                    value={formik.values.call_duration}
                    isInvalid={!!formik.errors.call_duration}
                    isValid={
                      formik.touched.call_duration &&
                      !formik.errors.call_duration
                    }
                    onBlur={formik.handleBlur}
                    readOnly={true}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.call_duration}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group className="form-group mb-3">
                  <Form.Label className="form-label">حالة المكالمة</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="call_status"
                    onChange={formik.handleChange}
                    value={formik.values.call_status}
                    isInvalid={!!formik.errors.call_status}
                    isValid={
                      formik.touched.call_status && !formik.errors.call_status
                    }
                    onBlur={formik.handleBlur}
                    readOnly={true}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.call_status}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <button
              disabled={
                loading ||
                supError ||
                error ||
                localStorage.getItem("role") === "Quality Dept. Manager"
              }
              type="submit"
              className="btn btn-primary w-100"
            >
              بحث
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SearchCall;
