import React from "react";

const CallDetails = ({ CallDetails }) => {
  return (
    <div className="card ">
      <div className="card-body">
        <div className="row">
          <h5 className="card-title fw-semibold mt-3 mb-4">
            {" "}
            تفاصيل المكالمة :{" "}
            <span className="card-subtitle text-muted">
              {CallDetails?.call?.uid}{" "}
            </span>
          </h5>
          <div className="col-md-3">
            <div className="card text-center order-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <h5 className="card-title">اسم الموظف</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {CallDetails?.extension?.name}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-center order-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <h5 className="card-title">القسم</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {CallDetails?.call?.department?.name}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-center order-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <h5 className="card-title">تاريخ المكالمة</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {CallDetails?.call?.call_date.substring(0, 11)}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-center order-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <h5 className="card-title">وقت المكالمة</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {CallDetails?.call?.call_date.substring(
                    11,
                    CallDetails.call.call_date.length
                  )}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-center order-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <h5 className="card-title">مدة المكالمة</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {CallDetails?.call?.duration}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-center order-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <h5 className="card-title"> المكالمة من</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {CallDetails?.call?.call_from}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-center order-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <h5 className="card-title"> المكالمة الي</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {CallDetails?.call?.call_to}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-center order-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <h5 className="card-title">حالة المكالمة</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {CallDetails?.call?.status === "Outbound" ? "صادرة" : "واردة"}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallDetails;
