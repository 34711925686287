import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchSingleEmployee = createAsyncThunk(
  "employee/fetchSingleEmployee",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get(`/users/user/data/${id}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default fetchSingleEmployee;
