import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fetchSingleExtension from "../../../store/allExtension/actGetSingelExtension";
import { useEffect } from "react";
import { useFormik } from "formik";
import fetchAllDepartments from "../../../store/department/actGetAllDepartments";
import { Form } from "react-bootstrap";
import editExtension from "../../../store/allExtension/actEdittExtension";

const EditExtension = () => {
  const { extention, loading } = useSelector((state) => state.extensionSlice);
  const { data } = useSelector((state) => state.departmentSlice);

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSingleExtension(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchAllDepartments());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      name: extention ? extention?.name : "",
      route: extention ? extention?.route : "",
      department_id: extention ? extention?.department_id : "",
      employee_id: extention ? extention?.employee_number : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        editExtension({
          id: extention?.id,
          name: values.name,
          route: values.route,
          department_id: values.department_id,
          employee_number: values.employee_id,
        })
      )
        .unwrap()
        .then(() => navigate("/dashboard/all-extensions"));
    },
  });
  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body p-4">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label className="form-label fw-semibold">الاسم</label>
                <div className="input-group border rounded-1">
                  <span
                    className="input-group-text bg-transparent px-6 border-0"
                    id="basic-addon1"
                  >
                    <i className="ti ti-building-arch fs-6"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control border-0 ps-2"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label className="form-label fw-semibold">رقم التحويلة</label>
                <div className="input-group border rounded-1">
                  <span
                    className="input-group-text bg-transparent px-6 border-0"
                    id="basic-addon1"
                  >
                    <i className="ti ti-building-arch fs-6"></i>
                  </span>
                  <input
                    type="number"
                    className="form-control border-0 ps-2"
                    name="route"
                    onChange={formik.handleChange}
                    value={formik.values.route}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="">
                <Form.Label>اسم القسم</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="department_id"
                  onChange={formik.handleChange}
                  value={formik.values.department_id}
                >
                  <option>اختر القسم</option>
                  {data.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="form-group mb-3">
                <Form.Label className="form-label">الرقم الوظيفي</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control  ps-2"
                  placeholder="ادخل الرقم الوظيفي"
                  name="employee_id"
                  onChange={formik.handleChange}
                  value={formik.values.employee_id}
                  isInvalid={!!formik.errors.employee_id}
                  isValid={
                    formik.touched.employee_id && !formik.errors.employee_id
                  }
                  onBlur={formik.handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.employee_id}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-success w-100"
            disabled={loading}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <>تعديل</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditExtension;
