import React from "react";
import CallDetails from "../../../quailty-ui/callDetails/CallDetails";
import EvaluatorUnvalidCallDetails from "../../../quailty-ui/callDetails/EvaluatorUnvalidCallDetails";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import SuperVisorCallDetails from "../../../quailty-ui/callDetails/SuperVisorCallDetails";
import withGuard from "../../../utils/withGurard";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import fetchSingleViolation from "../../../../store/supervisor/actGetsingleViolation";
import Loading from "../../../quailty-ui/Loading_page/Loading";

const SupervisorUnvalidCallDetailsPage = () => {
  const { singleViolation, loading } = useSelector(
    (state) => state.supervisorSlice
  );
  // useEffect(() => {
  //   if (localStorage.getItem("role" === "System Admin")) {

  //   }
  // });
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleViolation(id));
  }, [dispatch, id]);

  return (
    <>
      <Breadcrumb
        breadcrumbHeading="صفحة تفاصيل المكالمة"
        dashboard="لوحة التحكم"
        breadcrumb="تفاصيل تقييم المكالمة"
      />
      <Loading loading={loading}>
        <CallDetails CallDetails={singleViolation} />
        <div className="row">
          <div className="col-md-6">
            <EvaluatorUnvalidCallDetails violation={singleViolation} />
          </div>
          <div className="col-md-6">
            <SuperVisorCallDetails
              loading={loading}
              violation={singleViolation}
            />
          </div>
        </div>
      </Loading>
    </>
  );
};

export default withGuard(SupervisorUnvalidCallDetailsPage);
