import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import sendSupervisorEvaluation from "../../../store/supervisor/actSendSupervisorEvaluation";
import * as Yup from "yup";

const formSchema = Yup.object().shape({
  accreditation: Yup.string().required("هذا الحقل مطلوب!"),
});
const SuperVisorCallDetails = ({ loading, violation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      accreditation: "",
      comment: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      dispatch(
        sendSupervisorEvaluation({
          id: violation?.id,
          supervisor_id: localStorage.getItem("id"),
          is_violation_supervisor: values.accreditation,
          supervisor_note: values.comment,
        })
      )
        .unwrap()
        .then(() => navigate("/dashboard/supervisor-unvalid-calls"))
        .catch((error) => {});
    },
  });
  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12  mb-2">
              <Form.Group className="mb-3" controlId="">
                <Form.Label> حالة المخالفة</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="accreditation"
                  onChange={formik.handleChange}
                  value={formik.values.accreditation}
                  isInvalid={!!formik.errors.accreditation}
                  onBlur={formik.handleBlur}
                >
                  <option>اختر حالة المخالفة</option>
                  <option value="confirmed">معتمد</option>
                  <option value="not_confirmed">غير معتمد</option>
                  <option value="warning">تنبيه</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.accreditation}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-md-12">
              <div className="mb-3 ">
                <label
                  className="mb-3 fw-bold"
                  htmlFor="exampleFormControlTextarea1"
                >
                  ملاحظات
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  name="comment"
                  onChange={formik.handleChange}
                  value={formik.values.comment}
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={
                  loading ||
                  localStorage.getItem("role") === "Quality Dept. Manager"
                }
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  <>ارسال الي المدقق</>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SuperVisorCallDetails;
