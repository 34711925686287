import { useFormik } from "formik";
import React from "react";
import violation from "../../../store/call/actViolation";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { resetData, success } from "../../../store/call/callSlice";

const QualityEvaluation = () => {
  const [isViolation, setIsViolation] = useState(true);
  const { callDetails, call } = useSelector((state) => state.callSlice);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      startCall: "",
      callRecord: "",
      customerData: "",
      postponementReason: "",
      communication: "",
      anotherServiceQuestion: "",
      location: "",
      naqiProducts: "",
      reviewCustomarDataAndTime: "",
      confirmMessage: "",
      socialMedia: "",
      concliding: "",
      comments: "",
    },
    onSubmit: (values) => {
      if (isViolation) {
        dispatch(
          violation({
            intro: values.startCall ? values.startCall : false,
            record_mention: values.callRecord ? values.callRecord : false,
            confrim_data: values.customerData ? values.customerData : false,
            delay_question: values.postponementReason
              ? values.postponementReason
              : false,
            mention_msg: values.communication ? values.communication : false,
            other_services_check: values.anotherServiceQuestion
              ? values.anotherServiceQuestion
              : false,
            outro: values.concliding ? values.concliding : false,
            evaluator_note: values.comments ? values.comments : "",
            evaluator_id: localStorage.getItem("id"),
            uid: call ? call.call_id : "",
            call_from: call ? call.call_from : "",
            call_to: call ? call.call_to : "",
            call_date: call ? call.call_date : "",
            duration: call ? call.call_duration : "",
            status: call ? call.call_status : "",
            extension_id: callDetails ? callDetails.id.toString() : "",
            department_id: callDetails ? callDetails.department_id : "",
            form_type: callDetails ? callDetails.department.form_type : "",
            is_violation_evaluator: true,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetData());
            dispatch(success("تم تقييم المكالمة بنجاح"));
          })
          .catch((error) => {
            error && dispatch(resetData());
          });
      } else {
        dispatch(
          violation({
            intro: false,
            record_mention: false,
            confrim_data: false,
            delay_question: false,
            mention_msg: false,
            other_services_check: false,
            outro: false,
            evaluator_note: values.comments ? values.comments : "",
            evaluator_id: localStorage.getItem("id"),
            uid: call ? call.call_id : "",
            call_from: call ? call.call_from : "",
            call_to: call ? call.call_to : "",
            call_date: call ? call.call_date : "",
            duration: call ? call.call_duration : "",
            status: call ? call.call_status : "",
            extension_id: callDetails ? callDetails.id.toString() : "",
            department_id: callDetails ? callDetails.department_id : "",
            form_type: callDetails ? callDetails.department.form_type : "",
            is_violation_evaluator: false,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetData());
            dispatch(success("تم تقييم المكالمة بنجاح"));
          })
          .catch((error) => {
            error && dispatch(resetData());
          });
      }
    },
  });

  const disabledHandler = (obj) => {
    for (let key in obj) {
      if (
        (obj[key] === true && obj["comments"] === "") ||
        (obj[key] === true && obj["comments"])
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="startCall"
                onChange={formik.handleChange}
                value={formik.values.startCall}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم افتتاحية المكالمة (اسم الشركة والموظف)
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="callRecord"
                onChange={formik.handleChange}
                value={formik.values.callRecord}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم ذكر تسجيل المكالمة لغرض الجودة
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="customerData"
                onChange={formik.handleChange}
                value={formik.values.customerData}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم التأكد من اسم العميل والمدينة والحي
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="postponementReason"
                onChange={formik.handleChange}
                value={formik.values.postponementReason}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم سؤال العميل عن سبب التأجيل
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="communication"
                onChange={formik.handleChange}
                value={formik.values.communication}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم توضيح التواصل خلال 48 ساعة في حال رفع الموعد
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="anotherServiceQuestion"
                onChange={formik.handleChange}
                value={formik.values.anotherServiceQuestion}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم سؤال العميل عن اي خدمة اخرى
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                name="concliding"
                onChange={formik.handleChange}
                value={formik.values.concliding}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                عدم قول الختامية{" "}
              </label>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 ">
              <label
                className="mb-3 fw-bold"
                htmlFor="exampleFormControlTextarea1"
              >
                ملاحظات
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                name="comments"
                onChange={formik.handleChange}
                value={formik.values.comments}
              ></textarea>
            </div>
          </div>
          <div className="col-md-6">
            <button
              type="submit"
              onClick={() => setIsViolation(true)}
              className="btn btn-danger w-100"
            >
              ارسال مخالفة
            </button>
          </div>
          <div className="col-md-6">
            <button
              type="submit"
              onClick={() => setIsViolation(false)}
              className="btn btn-success w-100"
              disabled={disabledHandler(formik.values)}
            >
              لا توجد مخالفة
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default QualityEvaluation;
