import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import AddExtension from "../../../quailty-ui/all_extensions/AddExtension";
import withGuard from "../../../utils/withGurard";

const AddExtensions = () => {
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="التحويلات"
        dashboard="لوحة التحكم"
        breadcrumb="اضافة تحويلة"
      />
      <AddExtension />
    </>
  );
};

export default withGuard(AddExtensions);
