import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import UseExtensions from "../../../hooks/all_extensions/use-extensions";
import Loader from "../../../quailty-ui/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import fetchAllExtensions from "../../../../store/allExtension/actGetAllExtension";
import { useEffect } from "react";
import withGuard from "../../../utils/withGurard";
import MessageNotification from "../../../hooks/messages/MessageNotification";

const AllExtensions = () => {
  const { message } = useSelector((state) => state.messagesSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllExtensions());
  }, [dispatch]);
  const {
    customStyles,
    paginationComponentOptions,
    tableData,
    columns,
    data,
    loading,
  } = UseExtensions();
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="التحويلات"
        dashboard="لوحة التحكم"
        breadcrumb="جميع التحويلات"
      />
      {message && <MessageNotification message={message} />}
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-end mb-3">
            <button
              onClick={() => navigate("/dashboard/add-extensions")}
              className="btn text-white bg-primary rounded  p-6 d-flex align-items-center justify-content-center"
            >
              أضف تحويلة +
            </button>
          </div>
          <DataTableExtensions {...tableData} filterPlaceholder="بحث">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              highlightOnHover
              pointerOnHover
              customStyles={customStyles}
              defaultSortFieldId={1}
              persistTableHead
              noDataComponent={
                <span className="p-5">لا توجد بيانات لعرضها</span>
              }
              noContextMenu
              selectableRows
              selectableRowsHighlight
              progressPending={loading}
              progressComponent={<Loader />}
            />
          </DataTableExtensions>
        </div>
      </div>
    </>
  );
};

export default withGuard(AllExtensions);
