import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getEvaluatorAllUnViolationCalls = createAsyncThunk(
  "evaluator/getEvaluatorAllUnViolationCalls",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios.get(`/evaluations/evaluator/invalid/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export default getEvaluatorAllUnViolationCalls;
