import React from "react";
import SearchCall from "../../quailty-ui/searchCall/SearchCall";
import CallAction from "../../quailty-ui/callAction/CallAction";
import Breadcrumb from "../../quailty-ui/breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import Error from "../../quailty-ui/error/Error";
import withGuard from "../../utils/withGurard";

const CallSearchPage = () => {
  const { callDetails, error, success } = useSelector(
    (state) => state.callSlice
  );

  return (
    <>
      <Breadcrumb
        breadcrumbHeading="صفحة تقييم المكالمات"
        dashboard="لوحة التحكم"
        breadcrumb="تقييم المكالمات"
      />
      {success && (
        <div className="alert alert-success" role="alert">
          {success}
        </div>
      )}
      {error && <Error error={error} />}
      <SearchCall />
      {callDetails && <CallAction callDetails={callDetails} />}
    </>
  );
};

export default withGuard(CallSearchPage);
