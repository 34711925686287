import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const violation = createAsyncThunk(
  "call/violation",
  async (violationDetails, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await axios({
        method: "POST",
        url: `/evaluations/store/evaluator`,
        data: violationDetails,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors.uid[0]);
    }
  }
);

export default violation;
