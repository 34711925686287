import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fetchSingleDepartment from "../../../store/department/actGetSingleDepartment";
import { useFormik } from "formik";
import editDepartment from "../../../store/department/actEditDepartment";
import { Form } from "react-bootstrap";

const EditDepartment = () => {
  const { department, loading } = useSelector((state) => state.departmentSlice);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleDepartment(id));
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      department: department ? department?.name : "",
      scenario: department ? department?.form_type : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        editDepartment({
          id: department.id,
          name: values.department,
          form_type: values.scenario,
        })
      )
        .unwrap()
        .then(() => navigate("/dashboard/all-departments"));
    },
  });

  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body p-4">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-4">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label fw-semibold"
                >
                  تعدبل اسم قسم
                </label>
                <div className="input-group border rounded-1">
                  <span
                    className="input-group-text bg-transparent px-6 border-0"
                    id="basic-addon1"
                  >
                    <i className="ti ti-building-arch fs-6"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control border-0 ps-2"
                    placeholder="اسم القسم"
                    name="department"
                    onChange={formik.handleChange}
                    value={formik.values.department}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="">
                <Form.Label>السيناريو</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="scenario"
                  onChange={formik.handleChange}
                  value={formik.values.scenario}
                >
                  <option>اختر السيناريو الخاص بالقسم</option>
                  <option value="QualityEvaluation">سيناريو الجودة</option>
                  <option value="CoordinationEvaluation">
                    سيناريو التنسيق
                  </option>
                  <option value="SalesEvaluation">سيناريو المبيعات</option>
                  <option value="MultiEvaluation">
                    سيناريو قسم (الصيانة, الشكاوي, الخدمات, الرقم الموحد)
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-success w-100"
            disabled={loading}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <>تعديل</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditDepartment;
