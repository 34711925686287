import React from "react";
import Breadcrumb from "../../../quailty-ui/breadcrumb/Breadcrumb";
import CallDetails from "../../../quailty-ui/callDetails/CallDetails";
import EvaluatorUnvalidCallDetails from "../../../quailty-ui/callDetails/EvaluatorUnvalidCallDetails";
import SupervisorActionCall from "../../../quailty-ui/callDetails/SupervisorActionCall";
import withGuard from "../../../utils/withGurard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import fetchAuditorSingleViolation from "../../../../store/auditor/actGetSingleviolation";
import AuditorAction from "../../../quailty-ui/callDetails/AuditorAction";
import Loading from "../../../quailty-ui/Loading_page/Loading";

const AuditorUnvalidCallDetailsPage = () => {
  const { singleViolation, loading } = useSelector(
    (state) => state.auditorSlice
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchAuditorSingleViolation(id));
  }, [dispatch, id]);
  return (
    <>
      <Breadcrumb
        breadcrumbHeading="صفحة المكالمات المخالفة"
        dashboard="لوحة التحكم"
        breadcrumb="المكالمات المخالفة"
      />
      <Loading loading={loading}>
        <CallDetails CallDetails={singleViolation} />
        <div className="row">
          <div className="col-md-6">
            <EvaluatorUnvalidCallDetails violation={singleViolation} />
          </div>
          <div className="col-md-6">
            <SupervisorActionCall violation={singleViolation} />
          </div>
          <div className="col-md-12">
            <AuditorAction loading={loading} violation={singleViolation} />
          </div>
        </div>
      </Loading>
    </>
  );
};

export default withGuard(AuditorUnvalidCallDetailsPage);
